/* Navbar container styling */
.navbar {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space out items evenly */
  align-items: center; /* Center items vertically */
  padding: 0.3rem 2rem; /* Padding inside the navbar */
  background-color: #ffffff; /* Background color of the navbar */
  text-decoration: none; /* Remove text decoration */
  position: fixed; /* Fix the navbar to the viewport */
  top: 0; /* Stick to the top */
  left: 0; /* Align to the left edge */
  right: 0; /* Stretch across the full width */
  z-index: 1000; /* Ensure the navbar stays on top of other content */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.navbar-cart {
  position: relative;
}

.cart-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #ff3c00;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8rem;
  font-weight: bold;
}

/* Navbar brand styling */
.navbar-brand {
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
}
.navbar-logo {
  height: 65px; /* Adjust this value to match your desired logo height */
  width: auto; /* This will maintain the aspect ratio of your logo */
}

/* Navbar left section styling */
.navbar-left {
  font-size: 1.5rem; /* Font size */
  font-weight: bold; /* Bold text */
  text-decoration: none; /* Remove text decoration */
}

/* Navbar right section styling */
.navbar-right {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
}

/* Navbar item styling */
.navbar-item {
  margin-right: 1rem; /* Margin to the right */
  text-decoration: none; /* Remove text decoration */
  color: #333; /* Text color */
}

/* Navbar login button styling */
.navbar-login {
  margin-left: 0.5rem; /* Margin to the left */
  padding: 0.55rem 1.5rem; /* Padding inside the button */
  background-color: #ffffff; /* Background color */
  color: rgb(0, 0, 0); /* Text color */
  border: none; /* Remove border */
  border-radius: 25px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  text-decoration: none; /* Remove text decoration */
  font-size: 1rem; /* Font size */
  font-family: Arial, sans-serif; /* Font family */
}

/* Navbar login button hover effect */
.navbar-login:hover {
  background-color: #000000; /* Background color on hover */
  color: #ffffff; /* Text color on hover */
}

/* Navbar signup button styling */
.navbar-signup {
  margin-left: 0.5rem; /* Margin to the left */
  padding: 0.55rem 1.5rem; /* Padding inside the button */
  background-color: #ef4646; /* Background color */
  color: white; /* Text color */
  border: none; /* Remove border */
  border-radius: 25px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  text-decoration: none; /* Remove text decoration */
}

/* Navbar signup button hover effect */
.navbar-signup:hover {
  background-color: #f11a1a; /* Background color on hover */
}

/* Placeholder button styling */
button {
  margin-left: 0.5rem; /* Margin to the left */
}
.navbar-cart {
  margin-left: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}
button {
  margin-left: 0;
}
.navbar-cart:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Navbar logout button styling */
.navbar-logout {
  margin-left: 0.5rem; /* Margin to the left */
  padding: 0.45rem 1.3rem; /* Reduced padding inside the button */
  background-color: #ffffff; /* Background color */
  color: rgb(0, 0, 0); /* Text color */
  border: none; /* Remove border */
  border-radius: 25px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  text-decoration: none; /* Remove text decoration */
  font-size: 1rem; /* Font size */
  font-family: Arial, sans-serif; /* Font family */
}

/* Navbar logout button hover effect */
.navbar-logout:hover {
  background-color: #000000; /* Background color on hover */
  color: #ffffff; /* Text color on hover */
}

body {
  padding-top: 75px; /* This should match the height of your navbar */
}