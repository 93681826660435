.home {
  max-width: 100%;
  margin-bottom: 80px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #ffd700;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

nav {
  display: flex;
  align-items: center;
}

nav a, nav button {
  margin-left: 1rem;
  text-decoration: none;
  color: #333;
}

.login-btn, .signup-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-btn {
  background-color: white;
}

.signup-btn {
  background-color: #00308F;
  color: white;
}

.hero {
  position: relative;
  background-image: url('./hero-image.png');
  background-size: cover;
  background-position: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35); /* Adjust the color and transparency as needed */
  z-index: 1; /* Ensure the overlay is above the image but below the content */
}

.hero-content {
  text-align: center;
  color: white;
  z-index: 2;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.location-selector {
  display: inline-flex;
  align-items: center;
  background-color: #ffc107;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.location-selector i {
  margin-right: 0.5rem;
}

.location-selector select {
  background: transparent;
  border: none;
  font-size: 1rem;
  color: #333;
}

.seller-boxes {
  display: flex;
  justify-content: center;
  align-items: stretch; /* Changed from center to stretch */
  flex-wrap: wrap;
  gap: 20px;
  padding: 2rem;
  margin: auto 6vw;
}

.seller-link {
  text-decoration: none;
  color: inherit;
  flex-basis: calc(25% - 15px); /* For 4 items per row, accounting for gap */
  max-width: calc(25% - 15px);
  display: flex; /* Added */
  border-radius: 8px; /* Optional: for rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: for a subtle shadow */
}

.seller-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 100%; /* Added */
  background-color: #fff; /* Assuming a white background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.0); /* Optional: remove shadow */
  margin: auto;
}

/* Remove the grid-related properties as they're not needed here */
