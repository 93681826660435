.seller-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .seller-header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .seller-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 2rem;
  }
  
  .seller-info h1 {
    margin: 0;
    font-size: 2rem;
  }
  
  .cuisine-types {
    color: #666;
    margin: 0.5rem 0;
  }
  
  .seller-stats {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
  }
  
  .seller-stats span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .seller-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: #f0f0f0;
  }
  
  .seller-description {
    margin-bottom: 2rem;
  }
  
  .seller-menu {
    margin-bottom: 2rem;
  }
  
  .menu-filters, .meal-types {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .menu-filters button, .meal-types button {
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .menu-filters button:hover, .meal-types button:hover {
    background-color: #f0f0f0;
  }
  
  .popular-dishes h2 {
    margin-bottom: 1rem;
  }
  
  .dishes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 2rem;
  }
  
  .dish-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
  }
  
  .dish-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .dish-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .dish-card h3 {
    margin: 0.5rem;
  }
  
  .dish-card p {
    margin: 0.5rem;
    color: #666666;
  }
  
  .dish-rating {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .dish-price {
    font-weight: bold;
  }
  
  .add-to-cart {
    width: 100%;
    padding: 0.5rem;
    background-color: #ffdd00;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-to-cart:hover {
    background-color: #e6c700;
  }

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the popup is above other content */
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.popup-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.add-to-cart-popup {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #f71a1a;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.add-to-cart-popup:hover {
  background-color: #e10909;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.1s ease-in-out;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 500px;
  width: 100%;
}

.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}