/* components/Footer.css */
.footer {
  background-color: #f8f8f8;
  padding: 1.5rem 0 2rem;
  color: #333;
  font-size: 0.9rem;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
}

.footer-section {
  flex: 1;
  margin: 0.5rem;
  min-width: 150px;
}

.footer-section h2 {
  margin-bottom: 0.75rem;
  font-size: 1.5rem;
  color: #02346b;
}

.footer-section h2 a {
  text-decoration: none;
  color: #02346b;
}

.footer-section h2 a:hover {
  text-decoration: none;
}

.footer-section h3 {
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.4rem;
}

.footer-section a {
  color: #333;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

.social-icons a {
  margin-right: 0.75rem;
}

.footer-bottom {
  text-align: center;
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  border-top: 1px solid #ddd;
  font-size: 0.8rem;
}

.footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0.75rem;
}

.footer-links a {
  color: #333;
  text-decoration: none;
  margin: 0 8px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-buttons {
  display: flex;
  justify-content: center;
  margin-top: 0.75rem;
}