.seller-box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    margin: 1rem;
  }
  
  .food-image {
    height: 200px;
    overflow: hidden;
    border-radius: 8px;
  }
  
  .food-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .seller-info {
    padding: 1rem;
  }
  
  .seller-info h3 {
    margin: 0 0 0.5rem;
    font-size: 1.2rem;
  }
  
  .seller-info p {
    margin: 0.25rem 0;
    font-size: 0.9rem;
    color: #666;
  }
  
  .seller-info i {
    margin-right: 0.5rem;
    color: #ffc107;
  }
