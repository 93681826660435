.profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .profile-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .profile-info p {
    margin-bottom: 1rem;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #555;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .edit-button,
  .save-button,
  .cancel-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .edit-button,
  .save-button {
    background-color: #ed3f00;
    color: white;
  }
  
  .edit-button:hover,
  .save-button:hover {
    background-color: #000000;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #da190b;
  }
  
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }