.privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    font-size: 14px;
    text-align: left;
  }
  
  .privacy-container h1 {
    font-size: 24px;
    color: #1a1a1a;
    border-bottom: 2px solid #1a1a1a;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .privacy-container h2 {
    font-size: 20px;
    color: #333;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .privacy-container h3 {
    font-size: 18px;
    color: #444;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  
  .privacy-container p {
    margin-bottom: 15px;
  }
  
  .privacy-container ul {
    margin-bottom: 15px;
    padding-left: 30px;
  }
  
  .privacy-container li {
    margin-bottom: 8px;
  }
  
  @media (max-width: 600px) {
    .privacy-container {
      padding: 10px;
      font-size: 13px;
    }
    
    .privacy-container h1 {
      font-size: 22px;
    }
    
    .privacy-container h2 {
      font-size: 18px;
    }
    
    .privacy-container h3 {
      font-size: 16px;
    }
  }